<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.processEvent') }}</h5>
                <div class="p-field p-grid">
                    <label class="p-col-6 p-mb-2 p-md-1 p-mb-md-0">{{ $t('sportsbets.fixtureId') }}</label>
                    <div class="p-col-6 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="fixtureId" />
                    </div>
                    <Button @click="showProcessEvent()" :label="$t('buttons.getEventDetails')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                </div>
            </div>
            <div class="card" v-if="showEventDetails">
                <event-results :id="fixtureId" :fixtureId="fixtureId" :isProcess="true" :fixturesActive="true"></event-results>
            </div>
        </div>
    </div>
</template>
<script>
import EventResults from '../Results/EventResults.vue';

export default {
    components: { EventResults },
    data() {
        return {
            fixtureId: '',
            showEventDetails: false,
        };
    },
    watch: {},
    mounted() {},
    methods: {
        showProcessEvent() {
            this.showEventDetails = true;
        },
    },
};
</script>
